<template>
  <content-loader
    class="StoryPlaceholder"
    :height="250"
    :width="200"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="10" y="15" rx="0" ry="0" width="108" height="10" />
    <rect x="165" y="10" rx="0" ry="0" width="20" height="20" />
    <rect x="0" y="45" rx="0" ry="0" width="249.97" height="95" />
    <rect x="22" y="155" rx="0" ry="0" width="149.98" height="6" />
    <rect x="22" y="175" rx="0" ry="0" width="100" height="6" />
    <rect x="22" y="185" rx="0" ry="0" width="129.99" height="6" />
    <rect x="22" y="195" rx="0" ry="0" width="99.99" height="6" />
    <rect x="10" y="220" rx="0" ry="0" width="80" height="20" />
    <rect x="110" y="220" rx="0" ry="0" width="80" height="20" />
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  name: 'StoryPlaceholder',
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
